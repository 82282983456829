import {Link} from "react-scroll";
import myEBSLogo from '../media/ebslogo2.svg';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import 'bootstrap/dist/css/bootstrap.css';
import "./navbar.css"

export default function MyNavbar(){
    return(
        <Navbar expand="lg" className="Navbar_main">
            <Container>
                <Navbar.Brand>
                    <Link to="home" smooth={true} duration={500} style={{ cursor: 'pointer' }}>
                        <img
                            src={myEBSLogo}
                            width="50"
                            height="50"
                            className="d-inline-block align-top"
                            alt="EBS Logo"
                        />
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" className="Navbar_Toggle"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto">
                        <Nav.Item>
                            <Link to="about" duration={500} style={{ cursor: 'pointer' }}>About Us</Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Link to="module" duration={500} offset={-90} style={{ cursor: 'pointer' }}>Modules</Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Link to="contact" duration={500} style={{ cursor: 'pointer' }}>Contact Us</Link>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}