import {useEffect} from "react";
import './popup.css'

export default function Popup({ trigger, setTrigger, children }) {
    useEffect(() => {
        const handleClose = (e) => {
            if (e.type === 'scroll' || (e.type === 'keydown' && e.key === 'Escape')) {
                setTrigger(false);
            }
        };

        if (trigger) {
            window.addEventListener('scroll', handleClose);
            window.addEventListener('keydown', handleClose);
        }

        return () => {
            window.removeEventListener('scroll', handleClose);
            window.removeEventListener('keydown', handleClose);
        };
    }, [trigger, setTrigger]);

    return trigger ? (
        <div className={'Popup'} onClick={() => setTrigger(false)}>
            <div className={'PopupInner'}>
                {children}
            </div>
        </div>
    ) : null;
}