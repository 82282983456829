import {Col, Container, Row} from "react-bootstrap";
import "./intro.css";

export default function MyIntro(){
    return(
        <Container className={"Container_introduction"} fluid>
            <Row>
                <Col md={6} sm={12} className={"Col_introduction"}>
                    <h4 style={{ fontWeight: 300 }}>Introducing eBS</h4>
                    <h1>Enterprise Buisness System, <span style={{ fontWeight: 200 }}>Bringing people and technology together
                        for the success of your organization</span></h1>
                </Col>
                <Col className="Col_intro_points">
                    <ul>
                        <li>Generalized and Reliable ERP solution since 2003.</li>
                        <li>Real-time access to online information available.</li>
                        <li>Secure system with comprehensive audit trail.</li>
                        <li>Seamless interoperability with existing systems ensured.</li>
                        <li>Flexible, easy implementation with reliable support.</li>
                        <li>MS SQL Server-Powered Windows Desktop Application.</li>
                    </ul>
                </Col>
            </Row>
        </Container>
    );
}